import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/global.css";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";

import store from "./redux/store";
import Sidebars from "./component/Sidebar";
import ProductView from "./pages/ProductView";
import ProductForm from "./pages/ProductForm";
import ProductTable from "./pages/ProductTable";
import BillingTable from "./pages/BillingTable";
import BillingForm from "./pages/BillingForm";
import BillingView from "./component/BillingView";
import Dashboard from "./pages/Dashboard";
import BillingAdd from "./component/BillingAdd";
import BillingInvoice from "./pages/BillingInvoice";
import BillingInvoices from "./pages/BillingInvoice";
import DashBoard from "./component/DashBoard";
import ProductInvoice from "./pages/ProductInvoice";
import PurchaseForm from "./pages/PurchaseForm";
import OverAllDashboard from "./pages/OverAllDashboard";
import PurchaseTable from "./pages/PurchaseTable";
import Register from "./pages/Register";
import RegisterTable from "./pages/RegisterTable";

import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { useSelector } from "react-redux";
import SalaryAdd from "./component/SalaryAdd";
import SalaryView from "./component/SalaryView";
import DemoTest from "./component/DemoTest";
import BillingChanges from "./component/BillingChanges";
import Demo from "./component/Demo";
export default function Home() {
  const queryClient = new QueryClient();
  const count = useSelector((state) => state.user);
  console.log("count_login", count && count?.login);
  const [data, setData] = useState();
  useEffect(() => {
    setData(localStorage.getItem("login"));
  }, [localStorage.getItem("login")]);
  const value = sessionStorage.getItem("login");
  const { slug } = useParams();
  console.log("LoginPAge", slug);

  const [titleIntervalId, setTitleIntervalId] = useState(null);
  const [logoIntervalId, setLogoIntervalId] = useState(null);

  if (titleIntervalId === null) {
    const intervalId = setInterval(() => {
      let titleValue = sessionStorage.getItem("name");
      if (titleValue) {
        const titleTag = document.getElementsByTagName("title")[0];
        titleTag.innerHTML = titleValue;
        clearInterval(titleIntervalId);
      }
    }, 0.1);

    setTitleIntervalId(intervalId);
  }

  if (logoIntervalId === null) {
    const intervalId = setInterval(() => {
      let logoValue = sessionStorage.getItem("data");
      if (logoValue) {
        const logoTag = document.getElementById("logo");
        logoTag.href = `data:image/jpeg;base64,${logoValue}`;
        clearInterval(logoIntervalId);
      }
    }, 0.1);

    setLogoIntervalId(intervalId);
  }

  // document.getElementById('logo').addEventListener('click', function() {
  //         document.link = 'Title Changed on Button Click';
  //     });

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        {value ? (
          <>
            {}
            <Sidebars />
            <React.StrictMode>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route
                  path="/CustomerDashboard"
                  element={<OverAllDashboard />}
                />
                <Route path="/UserRegister" element={<Register />} />
                <Route path="/UserRegisterTable" element={<RegisterTable />} />
                <Route path="/ProductView" element={<ProductView />} />
                <Route
                  path="/ProductView/Invoice"
                  element={<ProductInvoice />}
                />
                <Route
                  path="/ProductView/ProductForm"
                  element={<ProductForm />}
                />
                <Route
                  path="/ProductView/ProductTable"
                  element={<ProductTable />}
                />
                <Route path="/BillingView" element={<BillingView />} />
                <Route
                  path="/Billing/BillingForm"
                  element={<BillingChanges />}
                />
                <Route path="/BillingForm" element={<BillingChanges />} />
                <Route
                  path="/Billing/BillingTable"
                  element={<BillingTable />}
                />
                <Route path="/BillingInvoice" element={<BillingInvoice />} />
                <Route
                  path="/Purchase/PurchaseForm"
                  element={<PurchaseForm />}
                />
                <Route
                  path="/Purchase/PurchaseTable"
                  element={<PurchaseTable />}
                />
                <Route path="/salaryAdd" element={<SalaryAdd />} />
                <Route path="/salaryView" element={<SalaryView />} />
                <Route path="/demo" element={<Demo />} />
              </Routes>
            </React.StrictMode>
          </>
        ) : (
          <>
            <React.StrictMode>
              <Routes>
                <Route path="/" element={<App />} />
                <Route path="/UserRegister" element={<Register />} />
              </Routes>
            </React.StrictMode>
          </>
        )}
      </QueryClientProvider>
    </BrowserRouter>
  );
}
